<template>
  <v-container v-if="tasks.length > 0" id="customer-service-tasks" fluid tag="section">
    <v-card class="ma-0">
      <v-card-title>
        <span v-if="recall">{{ $t('customerService.tasks.title.recall') }}</span><span v-else>{{ $t('customerService.tasks.title.done') }}</span>
      </v-card-title>
      <v-data-table :headers="headers" :options="options" :items="tasks" :loading="loading" :hide-default-footer="true">
        <template #[`item.action`]="{ item }">
          <span v-html="formatAction(item.action)" />
        </template>
        <template #[`item.phone`]="{ item }">
          {{ formatPhone(item) }}
        </template>
        <template #[`item.note`]="{ item }">
          <span v-html="formatNote(item.note)" />
        </template>
        <template #[`item.status`]="{ item }">
          {{ $t('customerService.tasks.status.' + item.status) }}
        </template>
        <template #[`item.user`]="{ item }">
          {{ item.user === undefined ? '' : item.user.username }}
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template #[`item.button`]="{ item }">
          <span v-if="editable && ['retry', 'suspend'].includes(item.status)">
            <v-btn small rounded color="accent" :loading="loading" @click.stop="editTask(item)">
              <v-icon medium dark :title="$t('customerService.tasks.button.edit')">
                mdi-pencil
              </v-icon>
            </v-btn>
            <!--            <v-btn v-else small rounded color="red" :loading="loading" @click.stop="deleteTask(item)">-->
            <!--              <v-icon medium dark :title="$t('customerService.tasks.button.delete')">-->
            <!--                mdi-close-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';

  export default {
    name: 'CustomerServiceTasks',
    props: {
      action: {
        type: String
      },
      data: {
        type: Array
      },
      editable: {
        type: Boolean,
        default: false
      },
      recall: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [
          {
            text: this.$i18n.t('customerService.tasks.table.id'),
            align: 'left',
            filterable: false,
            sortable: true,
            value: 'entityId'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.phone'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'phone'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.note'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'note'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.status'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'status'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.user'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'user'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'createdAt'
          },
          {
            text: this.$i18n.t('customerService.tasks.table.button'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'button'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        tasks: []
      };
    },
    watch: {
      data: async function () {
        await this.refreshData();
      },
      refresh: async function () {
        await this.refreshData();
      }
    },
    async mounted () {
      await this.refreshData();
    },
    methods: {
      formatAction (action) {
        let url;
        switch (action) {
          case 'call_inactive_customer':
            url = 'customer-service/inactive-customers/v2';
            break;
          case 'call_prospect':
            url = 'customer-service/prospects';
            break;
          case 'call_new_customer':
            url = 'customer-service/new-customers';
            break;
          case 'call_new_market_customer':
            url = 'customer-service/new-market-customers';
            break;
          default:
            return this.$i18n.t('customerService.tasks.action.' + String(action));
        }

        return '<a href="' + this.$router.resolve(url).href + '">' + this.$i18n.t('customerService.tasks.action.' + String(action)) + '</a>';
      },
      formatPhone (item) {
        const quotedPhoneRegExp = new RegExp('\\[tel:\\s?(\\+[0-9]+)\\]', 'i');
        if (typeof item.note === 'string' && item.note.startsWith('+') && item.note.length < 20) {
          if (item.note.startsWith('+33')) {
            return item.note.replace('+33', '0').replace(/(.{2})/g, '$1 ');
          }

          return item.note;
        } else if (quotedPhoneRegExp.exec(item.note)) {
          const result = quotedPhoneRegExp.exec(item.note)[1];
          if (result === undefined) {
            return '';
          }
          if (result.startsWith('+33')) {
            return result.replace('+33', '0').replace(/(.{2})/g, '$1 ');
          }

          return result;
        }

        if (String(item.entityId).length !== 9) {
          return '';
        }

        return ('0' + String(item.entityId)).replace(/(.{2})/g, '$1 ');
      },
      formatNote (note) {
        if (note === null) {
          return '';
        }

        if (typeof note === 'string') {
          try {
            return '<pre style="max-width:800px;white-space:pre-wrap;">' + JSON.stringify(JSON.parse(note), null, 4) + '</pre>';
          } catch (_e) {
            return note;
          }
        } else {
          return '';
        }
      },
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      async editTask (item) {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'customer/service/task?id=' + String(item.id));
          await this.refreshData();
          this.$emit('success', true);
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
      },
      // async deleteTask (item) {
      //   this.loading = true;
      //   try {
      //     await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'customer/service/task?id=' + String(item.id));
      //     await this.refreshData();
      //     this.$emit('success', false);
      //   } catch (error) {
      //     this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
      //   }
      //   this.loading = false;
      // },
      async refreshData () {
        if (typeof this.action !== 'string') {
          if (Array.isArray(this.data)) {
            this.tasks = this.data;
          }
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'customer/service/tasks?action=' + String(this.action));
          if (response.data !== undefined && response.data.tasks !== undefined) {
            this.tasks = await this.filterTasksToRecall(this.filterTasksByStatus(response.data.tasks));
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
      },
      filterTasksByStatus (tasks) {
        if (this.$can('read', 'Report.Meta')) {
          return tasks;
        }
        return tasks.filter((task) => ['retry', 'suspend'].includes(task.status));
      },
      async filterTasksToRecall (tasks) {
        let tasksToRecall = tasks;
        if (!this.recall) {
          return tasksToRecall;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'customer/call/incoming/missed');
          if (response.data !== undefined && Array.isArray(response.data.calls)) {
            tasksToRecall = tasksToRecall.filter((task) => task.status === 'retry' && response.data.calls.find((call) => {
              if (['call_inactive_customer', 'call_new_customer'].includes(this.action)) {
                return String(task.entityId).length > 8 && call.phone.includes(String(task.entityId));
              }
              return task.note.includes(call.phone);
            }));
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;

        return tasksToRecall;
      }
    }
  };
</script>
