var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tasks.length > 0)?_c('v-container',{attrs:{"id":"customer-service-tasks","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[(_vm.recall)?_c('span',[_vm._v(_vm._s(_vm.$t('customerService.tasks.title.recall')))]):_c('span',[_vm._v(_vm._s(_vm.$t('customerService.tasks.title.done')))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.tasks,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatAction(item.action))}})]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhone(item))+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatNote(item.note))}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.tasks.status.' + item.status))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user === undefined ? '' : item.user.username)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [(_vm.editable && ['retry', 'suspend'].includes(item.status))?_c('span',[_c('v-btn',{attrs:{"small":"","rounded":"","color":"accent","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.editTask(item)}}},[_c('v-icon',{attrs:{"medium":"","dark":"","title":_vm.$t('customerService.tasks.button.edit')}},[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }