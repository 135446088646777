<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase" style="width: 100%;">
            {{ $t('customerService.newCustomers.survey.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ displayName(customer) }} <a :href="'tel:' + phoneInt(customer)" target="_blank" class="white--text">{{ displayPhone(customer) }}</a>
          </div>
        </template>

        <v-container>
          <v-card class="main-card">
            <v-stepper v-model="step" vertical dark>
              <v-stepper-step :editable="step >= 1 && step < 4" :complete="step > 1" step="1">
                {{ $t('customerService.newCustomers.survey.start.title') }}
                <small>{{ $t('customerService.newCustomers.survey.start.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="1" class="white--text">
                {{ $t('customerService.newCustomers.survey.start.intro') }}
                <v-radio-group v-model="start" required>
                  <v-radio :label="$t('customerService.newCustomers.survey.start.notInterested')" value="notInterested" />
                  <v-radio :label="$t('customerService.newCustomers.survey.start.notNow')" value="notNow" />
                  <v-radio :label="$t('customerService.newCustomers.survey.start.yes')" value="yes" />
                </v-radio-group>
                <v-btn color="primary" :loading="loading" :disabled="loading || start===''" @click="validateStep1">
                  {{ $t('customerService.newCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :editable="step >= 2 && step < 4" :complete="step > 2" step="2">
                {{ $t('customerService.newCustomers.survey.review.title') }}
                <small>{{ $t('customerService.newCustomers.survey.review.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="2" class="white--text">
                <div class="mb-5 white--text">
                  <v-radio-group v-model="review" dense required class="ma-0 pa-0">
                    <v-row style="width:500px">
                      <v-col cols="4">
                        <v-radio value="good">
                          <template #label>
                            <v-avatar tile :size="30">
                              <img src="https://burgerbreton.net/cdn/image/website/icon/good.png">
                            </v-avatar>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio value="average">
                          <template #label>
                            <v-avatar tile :size="30">
                              <img src="https://burgerbreton.net/cdn/image/website/icon/average.png">
                            </v-avatar>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio value="bad">
                          <template #label>
                            <v-avatar tile :size="30">
                              <img src="https://burgerbreton.net/cdn/image/website/icon/bad.png">
                            </v-avatar>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                  <v-text-field v-if="review==='bad' || review==='average'" v-model="reviewComment" :label="$t('customerService.newCustomers.survey.review.comment')" hide-details dense />
                </div>
                <v-btn color="primary" :loading="loading" :disabled="loading || review===''" @click="validateStep2">
                  {{ $t('customerService.newCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :editable="step >= 2 && step < 4" :complete="step > 3" step="3">
                {{ $t('customerService.newCustomers.survey.discover.title') }}
                <small>{{ $t('customerService.newCustomers.survey.discover.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="3" class="white--text">
                <div class="mb-5 white--text">
                  <v-radio-group v-model="discover" required dense class="ma-0 pa-0">
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.searchEngine')" value="searchEngine" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.listing')" value="listing" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.socialMedia')" value="socialMedia" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.platform')" value="platform" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.friend')" value="friend" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.flyer')" value="flyer" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.brand')" value="brand" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.unknown')" value="unknown" />
                    <v-radio :label="$t('customerService.newCustomers.survey.discover.other')" value="other" />
                  </v-radio-group>
                  <v-text-field v-if="discover==='other'" v-model="discoverComment" :label="$t('customerService.newCustomers.survey.discover.other')" hide-details dense />
                </div>
                <v-btn color="primary" :loading="loading" :disabled="loading || discover===''" @click="validateStep3">
                  {{ $t('customerService.newCustomers.survey.button.continue') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="step > 4" step="4">
                {{ $t('customerService.newCustomers.survey.gift.title') }}
                <small>{{ $t('customerService.newCustomers.survey.gift.subtitle') }}</small>
              </v-stepper-step>
              <v-stepper-content step="4" class="white--text">
                <div v-if="review==='bad' || review==='average'">
                  {{ $t('customerService.newCustomers.survey.gift.sorry') }}
                </div>
                {{ $t('customerService.newCustomers.survey.gift.thanks', discount) }}
                <div v-if="customer && customer.phone">
                  {{ $t('customerService.newCustomers.survey.gift.phone') }}
                </div>
                <div v-if="customer && customer.email">
                  {{ $t('customerService.newCustomers.survey.gift.email') }}
                </div>
                <br>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="validate()">
                  {{ $t('customerService.newCustomers.survey.button.validate') }}
                </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-container>
        <v-card-actions />
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'NewCustomerSurveyForm',
    props: {
      customer: {
        type: Object,
        required: false
      }
    },
    data () {
      return {
        show: false,
        loading: false,
        valid: true,
        step: 1,
        start: '',
        review: '',
        reviewComment: '',
        discover: '',
        discoverComment: '',
        discount: {}
      };
    },
    watch: {
      customer: function () {
        this.show = this.customer !== undefined;
      },
      show: function () {
        this.step = 1;
        this.start = '';
        this.review = '';
        this.reviewComment = '';
        this.discover = '';
        this.discoverComment = '';
        this.discount = {};
      }
    },
    methods: {
      phoneInt (customer) {
        if (customer === undefined) {
          return '';
        }

        return phoneInt(customer.phone);
      },
      displayPhone (customer) {
        if (customer === undefined) {
          return '';
        }

        return displayPhone(customer.phone);
      },
      displayName (customer) {
        if (customer === undefined) {
          return '';
        }

        return customer.firstName + ' ' + customer.lastName;
      },
      async validateStep1 () {
        switch (this.start) {
          case 'notInterested':
            await this.callNewCustomer(this.start, 'suspend');
            this.validate();
            break;
          case 'notNow':
            await this.callNewCustomer(this.start, 'retry');
            this.validate();
            break;
          case 'yes':
          default:
            this.step = 2;
        }
      },
      validateStep2 () {
        this.step = 3;
      },
      async validateStep3 () {
        await this.callNewCustomer({
          review: this.review,
          reviewComment: this.reviewComment,
          discover: this.discover,
          discoverComment: this.discoverComment
        }, 'done');
        this.step = 4;
      },
      validate () {
        this.$emit('success', false);
        this.show = false;
      },
      async callNewCustomer (note, status) {
        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/new/call', {
            phone: this.customer.phone,
            email: this.customer.email,
            note: typeof note === 'string' ? note : JSON.stringify(note),
            status: status
          });
          if (response.data !== undefined && response.data.discount !== undefined) {
            this.discount = response.data.discount;
            this.discount.valueType = this.discount.valueType === 'percentage' ? '%' : '€';
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>
